<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <v-data-table
      dense
      :headers="headers"
      :items="data"
      sort-by="nome"
      class="elevation-1 p-5"
      :items-per-page="$dataTableConfig.getItemsPerPage()"
      :footer-props="$dataTableConfig.getFooterProps()"
      :loading-text="$dataTableConfig.getLoadText()"
      :page="page"
      :page-count="pageCount"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="d-flex flex-row-reverse"
        >
          <v-btn
              color="info"
              dark
              outlined
              class="mb-2 mt-5 mr-2"
              @click="syncProducoes"
          >
            Sincronizar
            <v-icon dark>
              mdi-cached
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="mt-5">
          <v-col>
            <v-select
              v-model="statusFilterValue"
              :items="statusOptions"
              outlined
              dense
              label="Status"
              @change="getData"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
              @keyup="getData"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <custom-label
          :color="getColor(item.status)"
          :text="getStatus(item.status)"
        />
      </template>
      <template v-slot:[`item.geolocate`]="{ item }">
        <div v-if="item.latitude && item.longitude">
          <a :href="'https://maps.google.com/?z=15&mrt=yp&t=k&q=' + item.latitude + '+' + item.longitude" target="_blank">
          {{item.latitude}}, {{item.longitude}}
          </a>
        </div>
        <div v-else>&nbsp;</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-spacer />
        <div>
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert
                text="Não foi possível obter essas informações"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
    >
      <v-card>
        <v-toolbar
            color="info"
            dark
        >
          <span class="headline">{{ formTitle }}</span>
        </v-toolbar>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            v-if="showForm"
        >
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                    v-model="editedItem.nome"
                    label="Nome"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="editedItem.sigla"
                    label="Sigla"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="editedItem.empresa_id"
                    :items="empresas"
                    label="Empresa"
                    item-text="nome"
                    item-value="id"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="editedItem.cnpj"
                    v-mask="'##.###.###/####-## '"
                    label="CNPJ"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="editedItem.telefone"
                    v-mask="'(##) ####-####'"
                    label="Telefone"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="editedItem.email"
                    label="Email"
                    dense
                    outlined
                    readonly
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="editedItem.latitude"
                    label="Latitude"
                    type="number"
                    max="90"
                    min="-90"
                    step="0.0000001"
                    outlined
                    dense
                    small-chips
                    show-size
                    :rules="latitudeRule"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="editedItem.longitude"
                    label="Longitude"
                    type="number"
                    max="180"
                    min="-180"
                    step="0.0000001"
                    outlined
                    dense
                    small-chips
                    show-size
                    :rules="longitudeRule"
                />
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <v-col>
                    <v-select
                        v-model="editedItem.status"
                        :items="statusField"
                        label="Status"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                    />
                  </v-col>
                  <v-col cols="auto" class="mt-2">
                    <custom-label
                        label
                        :color="getColor(editedItem.status)"
                        class="ml-2"
                        :text="getStatus(editedItem.status)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
                color="blue darken-1"
                text
                @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="validate"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-card-text v-else>
          <div class="text-center p-5">
            <h4 class="mb-4">
              Aguarde...
            </h4>
            <v-progress-circular
                :size="100"
                :width="2"
                color="primary"
                indeterminate
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogDelete"
        max-width="500px"
    >
      <v-card
          color="red"
          dark
      >
        <v-card-title class="headline word-breaker">
          <p>
            Tem certeza que deseja excluir a produção  {{ editedItem.nome }}?
          </p>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
              v-show="loadingDelete"
              :size="50"
              :width="6"
              color="white"
              indeterminate
          />
        </v-card-text>

        <v-card-actions v-show="!loadingDelete">
          <v-spacer />
          <v-btn
              color="white darken-1"
              text
              @click="closeDelete"
          >
            Não
          </v-btn>
          <v-btn
              color="white darken-1"
              text
              @click="deleteItemConfirm"
          >
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style type="text/css">
  .word-breaker {
    word-break: break-word;
  }

  .selected-status{
    font-size: 1.2rem;
  }
</style>

<script>
import axios from 'axios'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    status: false,
    valid: true,
    latitudeRule: [
      (v) => !!v || 'Necessário informar uma latitude',
    ],
    longitudeRule: [
      (v) => !!v || 'Necessário informar uma longitude',
    ],
    search: '',
    dialog: false,
    dialogDelete: false,
    empresas: [],
    statusOptions: [
      {
        text: 'Ativo',
        value: '1',
      },
      {
        text: 'Inativo',
        value: '0',
      },
      {
        text: 'Todos',
        value: '',
      },
    ],
    statusField: [
      {
        text: 'Ativo',
        value: 1,
      },
      {
        text: 'Inativo',
        value: 0,
      },
    ],
    statusFilterValue: '',
    moduloFilterValue: '',
    data: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      nome: '',
      sigla: '',
      empresa_id: 0,
      cnpj: '',
      email: '',
      telefone: '',
      status: 1,
    },
    defaultItem: {
      id: 0,
      nome: '',
      sigla: '',
      empresa_id: 0,
      cnpj: '',
      email: '',
      telefone: '',
      status: 1,
    },

    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,
    loading: true,
    loadingDelete: false,
    showForm: true,
    apiFetch: null,
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Adicionar Produção' : 'Editar Produção'
    },
    filter: (value) => {
      if (!this.status) {
        return true
      }

      return value < parseInt(this.status)
    },
    filteredData () {
      const data = this.data
      return data.filter((d) => d.status === 1)
    },
    headers () {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id',
        },
        { text: 'Empresa', value: 'nome', sortable: false },
        { text: 'Produção', value: 'sigla' },
        { text: 'Status', value: 'status' },
        { text: 'Geolocalização', value: 'geolocate' },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },

    options: {
      handler () {
        this.getData()
      },
    },
  },
  created () {
    this.initialize()
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Sistema', route: 'configuracoes/sistema' },
      { title: 'Produção' },
    ])

    this.initialize()
    this.getDataInstituicao()
    this.getData()
  },
  methods: {
    initialize () {},

    getData () {
      this.loading = true
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let params = `?page=${page}&qtde=${itemsPerPage}&sortBy=${sortBy}&sortDesc=${sortDesc}`

      if (this.search !== '') {
        params += `&search=${this.search}`
      }

      if (this.statusFilterValue !== '') {
        params += `&status=${this.statusFilterValue}`
      }

      this.cancel()

      const axiosSource = axios.CancelToken.source()
      this.apiFetch = { cancel: axiosSource.cancel }

      ApiService.get('/producao', params, {
        cancelToken: axiosSource.token,
      }).then((res) => {
        const dados = res.data
        this.loading = false
        this.totalItems = dados.total
        this.pageCount = dados.pageCount
        this.data = dados.data
      }).catch((error) => {
        this.loading = error.constructor.name === 'Cancel'
      })
    },

    cancel () {
      if (this.apiFetch) {
        this.apiFetch.cancel()
      }
    },

    getDataInstituicao () {
      this.empresas = this.$empresas.list()
    },

    getColor (boolField) {
      switch (boolField) {
        case 0:
          return 'danger'
        case 1:
          return 'success'
        case 2:
          return 'warning'
      }
    },

    getStatus (status) {
      switch (status) {
        case 0:
          return 'Inativo'
        case 1:
          return 'Ativo'
        case 2:
          return 'Suspenso'
      }
    },

    statusFilter (value) {
      if (this.statusFilterValue === '' || this.statusFilterValue === 'all') {
        return true
      }

      return value === this.statusFilterValue
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const index = this.editedIndex

        if (this.editedIndex > -1) {
          ApiService.put(`/producao/${this.editedItem.id}`, this.editedItem).then((res) => {
            Object.assign(this.data[index], res.data.data)
            this.showForm = true
            this.close()
            this.$refs.snackbar.show('Sucesso!', `Produção ${this.editedItem.sigla} atualizada com sucesso`)
            this.getData()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível atualizar a Produção', 'danger')
            console.error('Erro: ', error)
          })
        } else {
          ApiService.post('/producao', this.editedItem).then((res) => {
            this.data.push(res.data.data)
            this.showForm = false
            this.close()
            this.$refs.snackbar.show('Sucesso!', `Produção ${this.editedItem.sigla} adicionada com sucesso`)
            this.close()
            this.getData()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar a Produção', 'danger')
            console.error('Erro: ', error)
          })
        }
      }
    },

    editItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/producao/${this.editedItem.id}`).then(() => {
        this.data.splice(this.editedIndex, 1)
        this.closeDelete()
        this.$refs.snackbar.show('Sucesso!', `Produção ${this.editedItem.sigla} removida com sucesso`)
        this.loadingDelete = false
      }).catch((error) => {
        this.loadingDelete = false
        this.$refs.snackbar.show('Erro', 'Não foi possível remover a Produção', 'danger')
        console.error('Erro: ', error)
        this.closeDelete()
      })
    },

    close () {
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },

    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },

    syncProducoes () {
      this.loading = true

      ApiService.get('/producao/sincronizar')
        .then(() => {
          this.getData()
          this.loading = false
          this.$refs.snackbar.show('Sucesso', 'Sincronização concluída')
        }).catch((error) => {
          console.error('Erro: ', error)
          this.loading = false
          this.$refs.snackbar.show('Erro', 'Não foi possível sicronizar', 'danger')
        })
    },
  },
}
</script>
